var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-iam", attrs: { fluid: "", "pa-0": "" } },
    [
      _c("inn-breadcrumbs", { attrs: { items: _setup.breadcrumbs } }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("header", { staticClass: "app-welcome-message" }, [
            _c(
              "h2",
              [
                _c("v-icon", [_vm._v("mdi-account-multiple")]),
                _vm._v(" Identiteits- en toegangsbeheer"),
              ],
              1
            ),
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "sidebar", attrs: { cols: "2" } },
                [
                  _c(
                    "v-list",
                    { attrs: { nav: "" } },
                    [
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                text: "",
                                to: { name: "IAM-UsersOverview" },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-account-multiple"),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Gebruikers")]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                text: "",
                                to: { name: "IAM-UserGroupsOverview" },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v(_vm._s(_setup.mdiAccountGroup)),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Groepen")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                text: "",
                                to: { name: "IAM-ResourcesOverview" },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-apps")])],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Resources")]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                text: "",
                                to: { name: "IAM-ProjectsOverview" },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      _vm._s(
                                        _setup.mdiClipboardTextMultipleOutline
                                      ) + " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Projecten")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                text: "",
                                to: { name: "IAM-RolesOverview" },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v(_vm._s(_setup.mdiScriptTextOutline)),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Rollen")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-img", {
                        staticClass: "image",
                        attrs: {
                          src: require("@/assets/img/rolesandrights.png"),
                          width: "100%",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }